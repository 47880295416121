@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,900;1,700&display=swap');

.components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

// .site-layout-background {
//   // background: #fff;
// }

.body {
    height: 100vh;

    .content-page {
        height: calc(100% - 20px);
        margin: 10px !important;
        border-radius: 5px;

        box-shadow: 1px 1px 16px 4px var(--main-box-shadow-color);
        background-color: #fff !important;
    }

    .ant-layout-sider {
        height: 100vh;

        box-shadow: 1px 1px 5px 2px var(--main-box-shadow-color);

        .ant-layout-sider-children {
            box-shadow: rgb(0 0 0 / 15%) 0px 0px 54px;
        }
    }

    .ant-layout {
        .header {
            height: 55px;
            background-color: var(--main-color-sider);
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .ant-layout-sider-children {
            background-color: var(--main-color-sider);

            .slider-menu {
                .logo-app {
                    margin: 18px 0px;
                    font-size: 20px;
                    color: #76ba99;
                    font-weight: 800;
                }

                .info-house {
                    margin-top: 32px;
                    margin-bottom: 32px;

                    .name-building {
                        color: var(--main-color-gray);
                        font-size: 15px;
                        font-weight: 500;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.ant-btn {
    border-radius: 8px !important;

    >span {
        margin-left: 5px;
    }
}