.ant-modal-footer {
  > .ant-btn {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.ant-modal {
  > .ant-modal-content {
    border-radius: 10px;
    > .ant-modal-header {
      border-radius: 10px 10px 0 0;
    }
  }
}

.ant-input-affix-wrapper,
.ant-picker {
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-picker {
  width: 100%;
}

.ant-select-selector {
  border-radius: 10px !important;
  height: 35px !important;
  padding-top: 2px !important;
}

.ant-input-textarea {
  > textarea {
    border-radius: 10px;
  }
}

.ant-select-dropdown {
  border-radius: 10px;
}

.ant-input-number-affix-wrapper {
  width: 100%;
  border-radius: 10px;
  > .ant-input-number {
    border-radius: 10px !important;
  }
}

.ant-input-number-handler-wrap {
  border-radius: 10px;
}

.ant-select-selector {
  > .ant-select-selection-overflow {
    > .ant-select-selection-overflow-item {
      > .ant-select-selection-item {
        border-radius: 10px;
      }
    }
  }
}

.ql-container {
  box-shadow: 4px 6px 5px #d3d3d3;
  border-left: 1px solid #f1f1f1;
  min-height: 200px;
  border-radius: 0 0 10px 10px;
}

.ql-toolbar {
  border-radius: 10px 10px 0 0 !important;
  box-shadow: 4px 0 5px #d3d3d3;
}

.ant-input {
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
