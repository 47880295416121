.h-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__h-grid {
    flex-grow: 1;
    &__even-row {
      background-color: var(--even-row);
      width: 100% !important;
    }
  }
  &__create {
    display: flex;
    justify-content: end;
  }
  .ag-header-viewport {
    background-color: var(--grid-header);
  }
  .ag-pinned-left-header,
  .ag-pinned-right-header {
    background-color: var(--grid-header);
    color: var(--white);
  }
  .ag-header-container {
    background-color: var(--grid-header);
    .ag-header-row {
      color: var(--grid-white);
    }
  }
  .ag-header-cell-text {
    color: var(--grid-header-text-color);
  }
  .ag-floating-filter-button-button {
    color: var(--grid-header-text-color);
  }
}

.action-header {
  > .ag-cell-label-container {
    > .ag-header-cell-label {
      justify-content: center;
    }
  }
}
.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  border-right: 1px solid #babfc7;
}

.custom-height-tab-pane {
  height: calc(100vh - 222px);
}

.ag-root {
  // ::-webkit-scrollbar {
  //   width: 10px;
  //   height: 10px;
  //   background-color: #f5f5f5;
  // }
  // ::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //   background-color: #f5f5f5;
  //   border-radius: 10px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   background-color: #fff;
  //   background-image: -webkit-linear-gradient(
  //     top,
  //     #ebf5fd 0%,
  //     #a3c0e4 40%,
  //     #7694cb 51%,
  //     #5d7ab9 100%
  //   );
  // }
}

.ag-header-row-column-filter {
  color: black !important;
}
