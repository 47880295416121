:root {
  --main-color-sider: #fafbfc;
  --main-color-header-background: #cfd8db;
  --main-color-blue: #6cdbe0;
  --main-color-blue-dark: #7992dd;
  --main-color-gray: #7d96a2;
  --main-color-green: #42cd95;
  --main-color-organ: #dfbf8c;
  --main-color-white: #fff;
  --main-box-shadow-color: #d1b6b6;
  --main-border-radius: 5px;
  --spacers: 4;
  --focus-border: #bbbbbb !important;
  --separator-border: #d4d4d4 !important;
  --font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-size: 14px;
  --grid-white: #fff;
  --blue: #4b7be5;
  --red: #e65050;
  --green: #52c41a;
  --grid-header: rgb(243 244 246/1);
  --tw-text-opacity: 1;
  --grid-header-text-color: rgb(17 24 39/var(--tw-text-opacity));
  --even-row: #e6f7ff;
}
