.form-content{
    width: 450px;
    padding: 20px 20px 0px 20px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    .background-image{
        width: 100vh;
        height: 100vh;
        object-fit: contain;
    }
    .ant-form-item{        
        .ant-form-item-label{
            text-align: start;
        }
        .ant-form-item-control{
            text-align: start;
        }
        .ant-form-item{
            text-align: start;
        }
    }    
}