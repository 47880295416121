.login-page {
    display: flex;
    overflow: hidden;
    height: 100vh;
    .background-image{
        background: #3F4E4F;
        img{
            opacity: 0.8;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .box-form-login {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        justify-items: center;
        height: 100vh;
        position: absolute;
    }
}