@import url("/src/style/layout-app.scss");
.header-custom {
  .name-user {
    color: var(--main-color-gray);
    font-weight: 600;
    margin-left: 10px;
  }
  .logout-btn {
    background-color: var(--main-color-gray);
    border-color: var(--main-color-gray);
  }
}
